import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../layout/layout';
import ReactMarkdown from 'react-markdown';
import { GatsbyTemplateProps } from '../../../common/interfaces/gatsby-template-props.interface';
import { PressReleaseContext } from './press-release.gatsby';
import PressReleaseContactInfo from '../components/press-release-contact-info';
import { Helmet } from 'react-helmet';
import { Locale } from '../../../common/util/locales';
import { TopNav } from '../../layout';
import { MainFooter } from '../../layout/footer/footer.interfaces';
import { getMetaTagsJSX } from '../../page/page.template';
import { buildURL } from '../../layout/header/HrefLangs';
import { MetaTags } from '../../../contentful/content-types/elements/meta-tags.interface';
import { ContentfulNavigation } from '../../../contentful/content-types/navigation';

// Export query for gatsby to infer from template when gatsby-node invokes `createPage`
export const pageQuery = graphql`
  query($id: String!, $locale: String!) {
    pressRelease(id: { eq: $id }) {
      id
      title
      metaTags {
        metaTitle
        metaDescription
        twitterCustomImage {
          file {
            publicURL
          }
        }
        metaImage {
          file {
            publicURL
          }
        }
        facebookCustomImage {
          file {
            publicURL
          }
        }
      }
      subtitle
      orderDate
      body
      omitBoilerplate
      boilerplate
    }
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    navAbTestData: mainTopNav(
      contentfulId: { eq: "5g5R508U5ykm1WoKe5Q366" }
      locale: { eq: $locale }
    ) {
      ...mainTopNavAbTestFragment
    }
    baseURL: site {
      siteMetadata {
        baseURL
      }
    }
  }
`;

export interface PressReleaseDTO {
  id: string;
  locale: Locale;
  title: string;
  subtitle: string;
  metaTags?: MetaTags;
  orderDate: string;
  body: string;
  omitBoilerplate: boolean;
  boilerplate: string;
}

export interface PressReleaseData extends GatsbyTemplateProps<PressReleaseContext> {
  data: {
    pressRelease: PressReleaseDTO;
    navData: TopNav;
    redwoodNavData: ContentfulNavigation;
    redwoodRightNavData: ContentfulNavigation;
    utilityNavData: ContentfulNavigation;
    footerData: MainFooter;
    navAbTestData: TopNav;
    baseURL: any;
  };
}

const PressReleaseTemplate: FC<PressReleaseData> = ({ data, pageContext }: PressReleaseData) => {
  const { title, subtitle, body, boilerplate } = data.pressRelease;
  const url = buildURL(pageContext.locale, pageContext.pathname, pageContext.baseURL);
  const baseURL = data.baseURL?.siteMetadata?.baseURL || 'https://www.cloudflare.com';

  const fallbackMetaTags = [
    <meta name="title" content={title} />,
    <meta name="description" content={subtitle || title} />,
    <meta name="twitter:title" content={title} />,
    <meta name="twitter:description" content={subtitle || title} />,
    <meta property="og:title" content={title} />,
    <meta property="og:url" content={url} />,
    <meta property="og:description" content={subtitle || title} />,
  ];

  return (
    <Layout
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
      pageContext={pageContext}
      topNavData={data.navData}
      topNavAbTestData={data.navAbTestData}
      footerData={data.footerData}
    >
      <Helmet>
        <title>Cloudflare | Press Releases</title>
        {data.pressRelease.metaTags
          ? getMetaTagsJSX(baseURL, data.pressRelease.metaTags)
          : fallbackMetaTags}
      </Helmet>
      <section className="tile tile--borderless">
        <div className="tile__content tile__content--fixed">
          <div className="grid">
            <div className="grid__container">
              <div className="grid__item grid__item--two-thirds">
                <h1 className="marketing-h2 marketing-h2--long-title marketing-h2--left marketing-h2--margin-bottom">
                  {title}
                </h1>
                <h2 className="marketing-subtitle marketing-subtitle--margin-top">{subtitle}</h2>
                <span className="img-scaling" itemProp="articleBody">
                  <ReactMarkdown source={body} escapeHtml={false} />
                  {!!boilerplate ? <ReactMarkdown source={boilerplate} escapeHtml={false} /> : null}
                </span>
              </div>
              <div className="grid__item grid__item--third">
                <PressReleaseContactInfo variant="press-releases" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PressReleaseTemplate;
